import React, { useEffect, useState } from "react"
import * as s from "./_farm.module.scss"
import Seo from "../../components/seo"
import Layout from "../../Layout"
import Lightbox from "react-image-lightbox"
import Breadcrumbs from "../../components/Breadcrumbs"
import { HiOutlineLocationMarker } from "react-icons/hi"
import { AiOutlineSafetyCertificate } from "react-icons/ai"
import Masonry from "react-masonry-css"

const FarmPage = ({ pageContext: { node } }) => {
  const { slug, certifications, description, location } = node
  const [photoIndex, setPi] = useState(-1)

  const [images, setImages] = useState([])

  useEffect(() => {
    let farmImageSet = []
    if (node.images && node.images > 0) {
      for (let i = 0; i < node.images; i++) {
        farmImageSet.push(`/farms/${slug}/images/${i + 1}.jpg`)
      }
      setImages(farmImageSet)
    }
  }, [node.images, slug])

  return (
    <Layout>
      <Seo title={`Farm at ${location}`} />
      <Breadcrumbs title={`Farm at ${location}`} />

      <div className="container py-5">
        <div className="row">
          <div className="col-sm-6">
            <img
              src={`/farms/${slug}/cover.jpg`}
              alt={`Farm at ${location}`}
              className={"img-thumbnail"}
            />
          </div>
          <div className="col-sm-6">
            <ul className={s.list}>
              <li>
                <HiOutlineLocationMarker />
                {location}
              </li>
              <li>
                <AiOutlineSafetyCertificate />
                {certifications}
              </li>
            </ul>

            {description.split("\n").map((text, idx) => (
              <p key={idx}>{text}</p>
            ))}
          </div>

          <div className="col-sm-12 mt-4">
            <Masonry
              breakpointCols={{
                default: 4,
                1100: 3,
                700: 2,
                360: 1,
              }}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {images.map((image, idx) => (
                <div key={idx}>
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    onClick={() => setPi(idx)}
                    src={image}
                    tabIndex={idx + 2}
                    alt={`Farm at ${location}`}
                    className={"img-thumbnail"}
                  />
                </div>
              ))}
            </Masonry>
          </div>
        </div>
      </div>
      {photoIndex > -1 && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setPi(-1)}
          onMovePrevRequest={() =>
            setPi((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setPi((photoIndex + 1) % images.length)}
        />
      )}
    </Layout>
  )
}
export default FarmPage
